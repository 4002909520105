import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import moment from 'moment';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

//INTERNAL
import ImageUpload from '../../components/imageupload';
import DateSelector from '../../components/dateselector';
import RestServer from '../cRestServer';
import {orgurl, producturl}  from '../../environment';
import {dateFormat} from '../DateFormatter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faWindowClose, faQrcode, faPen} from '@fortawesome/free-solid-svg-icons';

//STORES
import userDataStore from '../../stores/zuserdatastore';


function EditEventContent() {
    
    //STORE SYSTEMS
    const { userData} = userDataStore();

    const history = useNavigate();

    const[event, setEvent]=useState(null);

    //FORM Information
    const[eventId, setEventId]=useState(null);
    const[eventname, setEventname] = useState(null);
    const[eventdescription, setEventdescription] = useState(null);
    const[venueid, setVenueId]=useState(null);
    const[selectedVenue, setSelectedVenue]=useState(null);
    const[eventDates, setEventDates]=useState(null);
    const[formvalidated, setFormvalidated] = useState(false);
    const[ticketType, setTicketType]=useState(null);

    const[images, setImages]=useState(null);

    const [venues, setVenues]=useState(null);
    const[tags, setTags]=useState(null);

    //Venue Rooms
    const[dates, setDates]=useState(null);
    const[selectedDate, setSelectedDate]=useState({});


    //SERVICE FEE
    const[serviceFees, setServiceFees]=useState([]);
    const[serviceFeeCharged, setServiceFeeCharged]=useState([])
    

    //TICKETS
    const[categoryId, setCategoryId]=useState(null);
    const[products, setProducts]=useState(null);
    const [eventTickets, setEventTickets]=useState(null);
    const[categories, setCategories]=useState([]);
    const[selectedProductId, setSelectedProductId]=useState(null);
    const[ticketName, setTicketName]=useState(null);
    const[ticketDescription, setTicketDescription]=useState(null);
    const[totalTicket, setTotalTicket]=useState(null);
    const[minPurchase, setMinPurchase]=useState(null);
    const[maxPurchase, setMaxPurchase]=useState(null);
    const[pricePerTicket, setPricePerTicket]=useState(null);
    const[selectedEditTicket, setSelectedEditTicket]=useState(null);
    const[selectedEditTicketIdx, setSelectedEditTicketIdx]=useState(null);
    const[editTicketDateIndex, setEditTicketDateIndex]=useState(null);
    const[selectedProductName, setSelectedProductName]=useState(null);

    const[selCategory, setSelCategory]=useState(null);
    const[groupTickets, setGroupTicket]=useState(null);


    //EDIT TICKET MODEL
    const[showEditTicketModel, setShowEditTicketModel]=useState(false);
    const[productIdEdit,setProductIdEdit]=useState(null);
    const[productNameEdit,setProductNameEdit]=useState(null);
    const[productDescriptionEdit,setProductDescriptionEdit]=useState(null);
    const[venueidEdit,setVenueidEdit]=useState(null);
    const[eventIdEdit,setEventIdEdit]=useState(null);
    const[companyidEdit,setCompanyidEdit]=useState(null);
    const[categoryEdit,setCategoryEdit]=useState({});
    const[additionalInfoEdit,setAdditionalInfoEdit]=useState({});
    const[additionalVendorInfoEdit,setAdditionalVendorInfoEdit]=useState({});
    const[totalAvailableEdit,setTotalAvailableEdit]=useState(0);
    const[totalRemainingEdit,setTotalRemainingEdit]=useState(0);
    const[priceEdit,setPriceEdit]=useState(0);
    const[isTaxableEdit,setIsTaxableEdit]=useState(false);
    const[isVendorProductEdit,setIsVendorProductEdit]=useState(false);
    const[taxEdit,setTaxEdit]=useState({});
    const[taxesIdEdit,setTaxesIdEdit]=useState(null);
    const[eventticketrequiredEdit,setEventticketrequiredEdit]=useState(false);
    const[isActiveEdit,setIsActiveEdit]=useState(false);
    


     //Modal Control
     const [showDepartmentContactsModel, setShowDepartmentContactsModel] = useState(false);
     const handleCloseDepartmentContactsModel=()=>{setShowDepartmentContactsModel(false);}

    
     const handleFormateDate=(epoch)=>{
        var etime;

        if(typeof epoch === 'string'){
            etime=parseInt(epoch);

        }else{
            etime=epoch;
        }

        var day = moment(etime); //milliseconds
        return day.format('dddd MMMM Do YYYY, h:mm:ss a');
    }

    /*

    const handleTag=(e)=>{
        if(e.keyCode===13){
            var taglist=[]
            tags.map((t)=>{ taglist.push(t)});
            taglist.push(e.target.value);
            setTags(taglist);
            document.getElementById("tagfield").value="";
        }
    }
    */

    const formatServiceFee=(fee)=>{
        var tranCharge="";
        if(fee.serviceFeeChargeBy==="TRANS"){
            tranCharge="transaction";
        }else{
            tranCharge="ticket";
        }
        if(fee.serviceFeeType==="VALUE"){
            return fee.serviceFeeName + " of "+ "$"+fee.serviceFeeAmount+" per " +tranCharge;

        }else{
            return fee.serviceFeeName + " of "+"%"+fee.serviceFeeAmount+" per "+tranCharge;
        }
    }

    const manageServiceFee=(fee,value)=>{
        var sfeeCharg=[];

        if(serviceFeeCharged.length===0){

            if(value.target.checked){
                
                sfeeCharg.push(fee);
                setServiceFeeCharged(sfeeCharg);
            }

        }else{

            if(value.target.checked){

                var feeNE=true;
                serviceFeeCharged.forEach((selFee)=>{
                    if(selFee.serviceFeedId===fee.serviceFeedId){
                        feeNE=false;  
                    }
                });

                if(feeNE){
                    sfeeCharg.push(fee);
                    setServiceFeeCharged(sfeeCharg);
                }

            }else{
                serviceFeeCharged.forEach((selFee)=>{
                    if(selFee.serviceFeedId !=fee.serviceFeedId){
                        sfeeCharg.push(fee);  
                    }

                });
                setServiceFeeCharged(sfeeCharg);
            }
        }
    }


    const handleCloseEditModel=(e)=>{
        setShowEditTicketModel(false);
    }

    const handleEditTiketModal=(idx, e)=>{

        console.info("handleEditTiketModal->000");

        var ticketMetadata={};
        e.metadata.map((d)=>{
           if(d.key==="proddetailfilter"){
            ticketMetadata={
                key:d.key,
                value:d.value
            }
           }
        })

        setShowEditTicketModel(true);
        setSelectedEditTicket(e);
        setSelectedEditTicketIdx(idx);

        setProductIdEdit(e.productId);
        setProductNameEdit(e.productName);
        setProductDescriptionEdit(e.productDescription);
        setVenueidEdit(e.venueid);
        setEventIdEdit(e.eventId);
        setCompanyidEdit(e.companyid);
        setCategoryEdit(e.category);
        setAdditionalInfoEdit(e.additionalInfo);
        setAdditionalVendorInfoEdit(e.additionalVendorInfo);
        setTotalAvailableEdit(e.totalAvailable);
        setTotalRemainingEdit(e.totalRemaining);
        setPriceEdit(e.price);
        setIsTaxableEdit(e.productId);
        setIsVendorProductEdit(e.productId);
        setTaxEdit(e.tax);
        setTaxesIdEdit(e.taxesId);
        setEventticketrequiredEdit(e.eventticketrequired);
        setIsActiveEdit(e.active);
        setTicketType(ticketMetadata);
 
        
    }

    const handleTicketUpdate=(e)=>{
        e.preventDefault();

        console.info("handleTicketUpdate")

        var metadataupdate=null;
       if(ticketType !==null){
        metadataupdate=[{
               key:"proddetailfilter",
               value:ticketType
            }];
        }

        const data={
            productId:productIdEdit,
            productName:productNameEdit,
            productDescription:productDescriptionEdit,
            venueid:venueidEdit,
            eventId:eventIdEdit,
            companyid:companyidEdit,
            category:categoryEdit,
            additionalInfo:additionalInfoEdit,
            additionalVendorInfo:additionalVendorInfoEdit,
            totalAvailable:totalAvailableEdit,
            totalRemaining:totalRemainingEdit,
            isTaxable:isTaxableEdit,
            isVendorProduct:isVendorProductEdit,
            tax:taxEdit,
            taxesId:taxesIdEdit,
            metadata:metadataupdate,
            eventticketrequired:eventticketrequiredEdit,
            isActive:isActiveEdit
        }
        console.info("handleTicketUpdate->data:");
        console.info(data);

        new RestServer().setUrl(producturl+'/api/prod/updateproduct')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;

            if(rtndata.messageTypeID===1){
                setShowEditTicketModel(false);
              
            }else{
                toast(rtndata.Message);
            }
        });

    }





    const handleTicketModel=(e)=>{
        setShowDepartmentContactsModel(e);
        setDates(JSON.parse(localStorage.getItem('eventdatestate')));
    }

        //THIS IS USED TO ADD THE VENUE ROOMS FROM MODAL
        const handleAddEventTicketForm =(e)=>{
            e.preventDefault();
    
            var category={}
            categories.map((cat)=>{
                if(cat.categoryid === categoryId){
                    category=cat;
                }
            });
            var selprods=[];
            if(selectedProductId !==null){
                products.map((prod)=>{
                    if(prod.productId ===selectedProductId){
                        selprods.push(prod);
                    }
                })
            }else{
                selprods.push({});
            }

            var ticketsMap=null;

            const eventticket={
                ticketname:ticketName,
                ticketdescription:ticketDescription,
                totalticket:totalTicket,
                minpurchase:minPurchase,
                maxpurchase:maxPurchase,
                eventsdata:selectedDate,
                category:category,
                priceperticket:pricePerTicket,
                products:selprods
            }
    
            eventTickets?.map((ticket)=>{
                ticketsMap.push(ticket);
            });
            ticketsMap.push(eventticket);

            const data={
                eventid:eventId,
                eventname: eventname,
                eventdescription : eventdescription,
                venueid: venueid,
               //category:category,
               companyid:userData[0].companyid,
               eventimage:JSON.parse(localStorage.getItem('imagedatastate')),
               tickets:ticketsMap,
               productdates:JSON.parse(localStorage.getItem('eventdatestate')),
               //metadata:[{}]
    
            }
            new RestServer().setUrl(producturl+'/api/prod/updateevent')
            .setMethod('POST')
            .flagReturnData(true)
            .setPostBody(data)
            .connect()
            .then(response=>{
                const rtndata =response.data;
                if(rtndata.messageTypeID===1){
                    localStorage.setItem("imagedatastate", null);
                    history('/events', {replace:true});
                    setTicketName("");
                    setTicketDescription("");
                    setTotalTicket('');
                    setSelectedProductId(null);
                    setShowDepartmentContactsModel(false);
                }else{
                    toast(rtndata.Message);
                }
            }); 
        }

    const formatedate=(d)=>{
        return dateFormat.getformateddate(d);  
    }

    const onClickDelete =(i)=>{
        var eventdates=[];
        images.map((idata, index) =>{
            if(i !==index){
                eventdates.push(idata);
            }
        });

        setImages(eventdates);
    }

    const handleSetSelectedDate=(e)=>{
        setSelectedDate(dates[e]);
    }

    const handleEditEvent = (e)=>{
        setFormvalidated(true);

        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }else{
        e.preventDefault();

        const data={
            eventid:eventId,
            eventname: eventname,
            eventdescription : eventdescription,
            venueid: venueid,
           //category:category,
           companyid:userData[0].companyid,
           eventimage:JSON.parse(localStorage.getItem('imagedatastate')),
           tickets:eventTickets,
           productdates:JSON.parse(localStorage.getItem('eventdatestate')),
           //metadata:[{}]

        }

        new RestServer().setUrl(producturl+'/api/prod/updateevent')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            if(rtndata.messageTypeID===1){
                localStorage.setItem("imagedatastate", null);
                history('/events', {replace:true})
            }else{
                toast(rtndata.Message);
            }
        });
    }

    }

    useEffect(() => {
        const urlData = window.location.pathname.split("/");

        new RestServer().setUrl(producturl+'/api/cat/allcompanycategory/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            setCategories(response.data);
        });

         //Event Tickets
         let eventTicketsArr=[]
         new RestServer().setUrl(producturl+'/api/prod/producteventid/'+urlData[2])
         .setMethod('GET')
         .flagReturnData(true)
         .connect()
         .then(response=>{
             const tickets=response.data;
             tickets.map((t)=>{
                 eventTicketsArr.push(t);
             })
         });
         setEventTickets(eventTicketsArr);

        new RestServer().setUrl(producturl+'/api/prod/allcompanyproducts/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            setProducts(response.data);
        });

        new RestServer().setUrl(orgurl+'/api/org/companyvenues/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            const vdata=response.data;
            setVenues(vdata);

        new RestServer().setUrl(producturl+'/api/prod/event/'+urlData[2])
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(eventresponse=>{
            const edata=eventresponse.data;

            vdata.map((vd)=>{
                if(vd.venueid === edata.venueid){
                    setSelectedVenue(vd);
                    setVenueId(vd.venueid);
                }
            })
    
            setEvent(edata);
            setEventId(edata.eventid)
            setEventname(edata.eventname);
            setEventdescription(edata.eventdescription);
            setEventDates(edata.productdates);
            
            setImages(edata.eventimage);


        });
        },[]);    
    },[]);

    return(
        <React.Fragment>
            <Row>
            <Form noValidate validated={formvalidated} onSubmit={handleEditEvent}>
            <Col lg={12} md={12}>
                <Row>
                    <Col lg={4} md={12} className="p-r-25 p-b-25">
                        <Row className="p-b-25 font-size-125 font-heavy9">
                            <Col> Event Information</Col>
                        </Row>
                        <Row className="glass-container-1">
                            <Col>
                            <Row className="p-t-10">
                                <Col >
                                    <Form.Group controlId ="eventname" onChange={(s)=>{setEventname(s.target.value)}}>
                                        <Form.Label><span className="font-size-75 clr-1">*</span> Event Name </Form.Label>
                                        <Form.Label>Event Name</Form.Label>
                                        <Form.Control className="clr-black" type="text" value={eventname} placeholder="event name" />
                                        <Form.Control.Feedback type="invalid" className="font-size-75">Event Name is Required</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10">
                                <Col>
                                    <Form.Group controlId ="eventdescription" onChange={(s)=>{setEventdescription(s.target.value)}}>
                                        <Form.Label><span className="font-size-75 clr-1">*</span> Event Name </Form.Label>
                                        <Form.Label>Event Description</Form.Label>
                                        <Form.Control as="textarea" value={eventdescription} rows={4} />
                                        <Form.Control.Feedback type="invalid" className="font-size-75">Event Name is Required</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10">
                                <Col lg={12}>  
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label><span className="font-size-75 clr-1">*</span> Venue</Form.Label>
                                        <Form.Control as="select" onChange={(s)=>{setVenueId(s.target.value)}}>
                                            <option value={selectedVenue?.venueid}>{selectedVenue?.venuename}</option>
                                            {
                                                venues?.map((venue)=>{
                                                    return <option value={venue.venueid}>{venue.venuename}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-25">
                                <Col lg={12}>
                                    <Row><Col className="font-heavy8"><span className="font-size-75 clr-1">*</span> Event Dates : </Col></Row>
                                    <Row><Col className="font-size-75 p-b-10  clr-1 font-heavy8">*date cannot be changed after event is created </Col></Row>
                                    {
                                       eventDates?.map((d)=>{
                                        return <Row><Col>{formatedate(d.epochtime)}</Col></Row>
                                       }) 
                                    }
                                    {/*<DateSelector data={eventDates} />*/}
                                </Col>
                            </Row>
                            <Row className="p-t-25">

                            </Row>
                    
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={4} md={12} className="p-r-25 p-b-25">
                        <Row className="p-b-25 font-size-125 font-heavy9">
                            <Col>Add Images</Col>
                        </Row>
                        <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1 -clr-primary-5">
                            <Col>
                                <Row>
                                    <Col>
                                        <ImageUpload />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {
                                            images?.map((image, index) =>{
                                                var eventimgloca=image.imageloc+image.imagename
                                                return <Row key={index} className="p-t-10 p-l-10 hoverpos">
                                                    <span className="remImage p-t-10" id="delete"></span>
                                                    <img src={eventimgloca}
                                                            className="p-t-10 img-container-2"
                                                            onClick={()=>{onClickDelete(index)}}
                                                            />
                                                </Row>
                                            })
                                        }
                                    </Col>
                                </Row>
                                
                            </Col>
                        </Row>

                        <Row className="p-t-25">
                            <Col>
                                <Row className="p-b-25 font-size-125 font-heavy9">
                                    <Col>Service Fees</Col>
                                </Row>
                                <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1">
                                    <Col>
                                        <Row className="p-l-25">
                                            <Col className="font-size-75 p-l-5">Events Ticket cannot be changed</Col>
                                        </Row>
                                        
                                            {
                                                event?.displayServiceFee?.map((fee)=>{
                                                    return  <Row className="p-t-25 p-l-25">
                                                        <Col lg={10}>{fee.serviceFeeName} of ${fee.serviceFeeAmount} per {fee.serviceFeeChargeBy}</Col>
                                                        
                                                    </Row> 
                                                })
                                            } 
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {/*
                        <Row className="p-b-25 p-t-25">
                            <Col>
                                <Row className="p-b-25 font-size-125 font-heavy9">
                                    <Col>Tags</Col>
                                </Row>
                                <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1">
                                    <Col>
                                        <Row>
                                            <Col><Form.Control  id="tagfield" className="clr-black" type="text" placeholder="tags"  
                                            onKeyDown={(e)=>{handleTag(e)}} defaultValue=""/></Col>
                                        </Row>
                                        <Row className="p-t-25 p-l-25 p-r-25">
                                            {
                                                tags?.map((tag)=>{
                                                    return  <Col className="container-1 center">{tag}</Col>
                                                })
                                            }
                                        
                                        </Row>  
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                         */}
                    </Col>
                    <Col lg={4} md={12} className="p-r-25 p-b-25">
                         {/* CONTACTS & Tags */}
                <Row>
                    <Col >
                        <Row className="p-b-25 font-size-125 font-heavy9">
                            <Col lg={6}> Edit Event Tickets</Col>
                            <Col lg={6} className="left" onClick={(s)=>{handleTicketModel(true)}}> <FontAwesomeIcon icon={faPlusSquare} /></Col>
                        </Row>
                        <Row>
                            <Col >
                                {
                                    eventTickets?.map((ticket, idx)=>{
                                        return<Row  className="p-b-20 p-r-20">
                                        <Col lg={12} className="glass-container-1 p-25 ">
                                            <Row className="p-b-10">
                                                <Col lg={4} className="font-size-300 font-heavy9"><FontAwesomeIcon icon={faQrcode} /> </Col>
                                                <Col lg={6}><span className="font-size-125 font-heavy9 p-t-20">{ticket.productName}</span></Col>
                                            </Row>
                                            

                                            <Row><Col className="font-size-100 clr-primary-6 font-light p-b-25">{ticket.productDescription}</Col></Row>
                                            <Row><Col className="font-size-75 clr-primary-6 font-heavy p-b-10">Total Tickets: {ticket.totalAvailable}</Col></Row>
                                            <Row><Col className="font-size-75 clr-primary-6 font-heavy">Price: {ticket.price}</Col></Row>
                                            
                                            <Row className="p-t-25">
                                                <Col lg={2} className="font-100 clr-primary-4 font-light ">
                                                    <button className="icon-container-1" onClick={()=>{handleEditTiketModal(idx, ticket)}}>
                                                        <FontAwesomeIcon icon={faPen} />
                                                        <span className="icon-container-1-title">Edit Ticket
                                                            <div className="icon-container-1-title-pointer"></div>
                                                        </span>
                                                    </button>
                                                </Col>
                                               
                                            </Row>
                                    
                                        </Col>
                                    </Row>;
                                    })
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                    </Col>
                </Row>
            </Col>

            <Col lg={5}  md={5} className="v-center">
                <Row  className="p-t-50">
                    <Col lg={4}  md={5} className="center">
                        <button className="icon-text-container-0 p-10 font-heavy9" onClick={handleEditEvent}>
                            <FontAwesomeIcon icon={faPlusSquare} />&nbsp;&nbsp; Update
                        </button>
                    </Col>
                
                    <Col lg={4}  md={5} className="center">
                        <button className="icon-text-container-0 p-10 font-heavy9">
                            <FontAwesomeIcon icon={faWindowClose} />&nbsp;&nbsp; Cancel
                        </button>
                    </Col>
                </Row>
            </Col>
            </Form>
        </Row>

      {/*Edit Event Ticket*/}
      <Modal show={showEditTicketModel} onHide={handleCloseEditModel}>
            <Form>
            <Modal.Header closeButton>
            <Modal.Title>Update Event Ticket Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="p-t-10">
                    <Col >
                        <Form.Group controlId ="ticketname" onChange={(s)=>{setProductNameEdit(s.target.value)}}>
                            <Form.Label>Event Ticket Name</Form.Label>
                            <Form.Control className="clr-black" type="text" placeholder="event ticket name" value ={productNameEdit} required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="p-t-10">
                    <Col>
                        <Form.Group controlId ="ticketdescription" onChange={(s)=>{setProductDescriptionEdit(s.target.value)}}>
                            <Form.Label>Event Ticket Description</Form.Label>
                            <Form.Control as="textarea" value ={productDescriptionEdit} rows={4} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="p-t-10">
                    <Col>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Categories</Form.Label>
                            <Form.Control as="select" onChange={(s)=>{setCategoryEdit(s.target.value)}} required>
                                <option value={categoryEdit}>{categoryEdit.categoryname}</option>
                                    {
                                        categories.map((category)=>{
                                            return <option value={category}>{category.categoryname}</option>
                                        })
                                    }
                            </Form.Control >
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="p-t-10">
                    <Col>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Ticket Type</Form.Label>
                            <Form.Control as="select" onChange={(s)=>{setTicketType(s.target.value)}} required>
                                <option value={ticketType?.value}>{ticketType?.value}</option>
                                <option value="GENERAL">GENERAL</option>
                                <option value="FREE">FREE</option>
                                <option value="VIP">VIP</option>
                                <option value="PREMIUM">PREMIUM</option>
                            </Form.Control >
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="p-t-10">
                    <Col>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Dates</Form.Label>
                            <Form.Control as="select" onChange={(s)=>{handleSetSelectedDate(s.target.value)}}>
                                <option>Select Date...</option>
                                    {
                                        dates?.map((date, index)=>{
                                            return <option value={index}>{handleFormateDate(date.epochtime)}</option>
                                        })
                                    }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                
                {((selCategory?.categorycode==="EVENTTABLEWITHPRODUCT")?<Row className="p-t-10">
                    <Col >
                        <Form.Group controlId ="totalticket" onChange={(s)=>{setGroupTicket(s.target.value)}} required>
                            <Form.Label>Number of Tickets</Form.Label>
                            <Form.Control className="clr-black" type="text"  placeholder="Number of Tickets" />
                        </Form.Group>
                    </Col>
                </Row>:"")} 
                <Row className="p-t-10">
                    <Col >
                        <Form.Group controlId ="totalticket" onChange={(s)=>{setTotalAvailableEdit(s.target.value)}} required>
                            <Form.Label>Number Of Ticket(s) Included</Form.Label>
                            <Form.Control className="clr-black" type="text" value ={totalAvailableEdit} placeholder="total tickets" />
                        </Form.Group>
                    </Col>
                </Row>
               
                <Row className="p-t-10">
                    <Col>
                        <Form.Group controlId ="totalticket"  readonly>
                            <Form.Label>Ticket Price <span className="font-size-50 clr-1 font-heavy8"> *ticket price cannot be altered</span></Form.Label>
                            <Form.Control className="clr-black" type="text" value ={priceEdit} placeholder="ticket price" />
                        </Form.Group>
                    </Col>
                </Row>
                {((selCategory?.categorycode==="PRODUCTANDTICKET" || selCategory?.categorycode==="EVENTTABLEWITHPRODUCT")?<Row className="p-t-10">
                <Col>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Products</Form.Label>
                        <Form.Control as="select" onChange={(s)=>{setSelectedProductId(s.target.value)}}>
                            <option>Select Product...</option>
                                {
                                    products?.map((product)=>{
                                        return <option value={product.productId}>{product.productName}</option>
                                    })
                                }
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>:"")}  
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModel}>
                Cancel
            </Button>
            <Button variant="primary" type="submit" onClick={handleTicketUpdate}>
                Update Ticket
            </Button>
            </Modal.Footer>
            </Form>
        </Modal>


         {/*Add Event Ticket*/}
         <Modal show={showDepartmentContactsModel} onHide={handleCloseDepartmentContactsModel}>
            <Form onSubmit={handleAddEventTicketForm}>
            <Modal.Header closeButton>
            <Modal.Title>Add Event Ticket Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="p-t-10">
                    <Col >
                        <Form.Group controlId ="ticketname" onChange={(s)=>{setTicketName(s.target.value)}}>
                            <Form.Label>Event Ticket Name</Form.Label>
                            <Form.Control className="clr-black" type="text" placeholder="event ticket name" required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="p-t-10">
                    <Col>
                        <Form.Group controlId ="ticketdescription" onChange={(s)=>{setTicketDescription(s.target.value)}}>
                            <Form.Label>Event Ticket Description</Form.Label>
                            <Form.Control as="textarea" rows={4} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="p-t-10">
                    <Col>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Categories</Form.Label>
                            <Form.Control as="select" required>
                                <option>Select Category...</option>
                                    {
                                        categories.map((category)=>{
                                            return <option value={category.categoryid}>{category.categoryname}</option>
                                        })
                                    }
                            </Form.Control >
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="p-t-10">
                    <Col>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Dates</Form.Label>
                            <Form.Control as="select" onChange={(s)=>{handleSetSelectedDate(s.target.value)}}>
                                <option>Select Date...</option>
                                    {
                                        dates?.map((date, index)=>{
                                            return <option value={index}>{handleFormateDate(date.epochtime)}</option>
                                        })
                                    }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                
                {((selCategory?.categorycode==="EVENTTABLEWITHPRODUCT")?<Row className="p-t-10">
                    <Col >
                        <Form.Group controlId ="totalticket" onChange={(s)=>{setGroupTicket(s.target.value)}} required>
                            <Form.Label>Number of Tickets</Form.Label>
                            <Form.Control className="clr-black" type="text" placeholder="Number of Tickets" />
                        </Form.Group>
                    </Col>
                </Row>:"")} 
                <Row className="p-t-10">
                    <Col >
                        <Form.Group controlId ="totalticket" onChange={(s)=>{setTotalTicket(s.target.value)}} required>
                            <Form.Label>Total Ticket</Form.Label>
                            <Form.Control className="clr-black" type="text" placeholder="total tickets" />
                        </Form.Group>
                    </Col>
                </Row>
               
                <Row className="p-t-10">
                    <Col>
                        <Form.Group controlId ="totalticket" onChange={(s)=>{setPricePerTicket(s.target.value)}} required>
                            <Form.Label>Ticket Price</Form.Label>
                            <Form.Control className="clr-black" type="text" placeholder="ticket price" />
                        </Form.Group>
                    </Col>
                </Row>
                {((selCategory?.categorycode==="PRODUCTANDTICKET" || selCategory?.categorycode==="EVENTTABLEWITHPRODUCT")?<Row className="p-t-10">
                <Col>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Products</Form.Label>
                        <Form.Control as="select" onChange={(s)=>{setSelectedProductId(s.target.value)}}>
                            <option>Select Product...</option>
                                {
                                    products?.map((product)=>{
                                        return <option value={product.productId}>{product.productName}</option>
                                    })
                                }
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>:"")}  
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDepartmentContactsModel}>
                Cancel
            </Button>
            <Button variant="primary" type="submit">
                Add Ticket
            </Button>
            </Modal.Footer>
            </Form>
        </Modal>
        </React.Fragment>
    );
}

export default EditEventContent;