function base(){
    const baseurl="https://192.168.68.56:1110";
    return baseurl;
}

export const orgurl=process.env.REACT_APP_URL_ORG; //AWS
export const apiurl=process.env.REACT_APP_URL_API; //AWS
export const commurl=process.env.REACT_APP_URL_COM; //AWS
export const accpakurl=process.env.REACT_APP_URL_ACC; //AWS
export const billcapurl=process.env.REACT_APP_URL_BLL; //AWS
export const conurl=process.env.REACT_APP_URL_CON; //AWS
export const producturl=process.env.REACT_APP_URL_PRD; //AWS
export const loginurl=process.env.REACT_APP_URL_REDIRECT;

export default base;